import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const speaking = (props) => (
    <Layout>
        <div>
            <Helmet>
                <title>Speaking Engagements</title>
                <meta name="description" content="Jonathan Agoot - Speaking Engagements" />
            </Helmet>
            <div id="main">
                <section id="banner" className="style2">
                    <div className="inner">
                        <header className="major">
                            <h1>Speaking Engagements</h1>
                        </header>
                        <div className="content">
                            <p>Book me for your next event.</p>
                        </div>
                    </div>
                </section>
                <section id="one">
                    <div className="inner">
                        <h2>Topics</h2>
                        <h3>Each topic can be customized to your business or organizational needs.</h3>
                        <ul>
                            <li>Emerging Technology</li>
                            <li>Digital Innovation</li>
                            <li>Extended Reality</li>
                            <li>Prototyping</li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    </Layout>
)

export default speaking
